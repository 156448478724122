import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import StatisticsWeapons from "@/game-fortnite/StatisticsWeapons.jsx";
import PageContainer from "@/shared/PageContainer";
import PageHeader from "@/shared/PageHeader";
import { useRoute } from "@/util/router-hooks.mjs";

const TABS = {
  weapons: {
    component: StatisticsWeapons,
    url: "/fortnite/stats/weapons",
    text: [
      "fortnite:stats.weapons.title",
      "Fortnite Weapon Stats",
    ] as Translation,
    meta: {
      title: ["fortnite:meta.stats.weapons.title", "Fortnite Weapon Stats"],
    },
  },
};

function Statistics() {
  const {
    parameters: [tab],
  } = useRoute();

  const TabComponent = TABS[tab]?.component;

  return (
    <PageContainer>
      <PageHeader title={TABS[tab]?.meta?.title} />
      {TabComponent ? <TabComponent /> : null}
    </PageContainer>
  );
}

export default Statistics;

export function meta(tabId) {
  const tabMeta = TABS[tabId]?.meta;
  const seasonId = readState.fortnite.seasons?.[0];
  return {
    title: tabMeta?.title ?? ["fortnite:meta.stats.title", "Fortnite Stats"],
    description: [
      "fortnite:meta.stats.desc",
      "Optimize your strategy and climb the ranks by choosing the best weapon for every battle. Discover the most popular and effective weapons across every rank of the meta in {{seasonName}}.",
      { seasonName: seasonId?.name },
    ],
    subtitle: !IS_APP,
  };
}
