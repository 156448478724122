import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { TextInput } from "clutch/src/TextInput/TextInput";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import {
  Container,
  RankedChartTooltip,
} from "@/game-fortnite/components/CommonComponents.jsx";
import type { Rarity } from "@/game-fortnite/components/ItemBox";
import { ItemBox } from "@/game-fortnite/components/ItemBox";
import {
  FiltersContainer,
  Modes,
  Queues,
  Ranks,
  Seasons,
  Sort,
  WeaponCategories,
} from "@/game-fortnite/components/Selects";
import { FORTNITE_PLAYLISTS } from "@/game-fortnite/constants/playlists.mjs";
import { FORTNITE_RANKS } from "@/game-fortnite/constants/ranks.mjs";
import { WEAPON_STAT_COLUMNS } from "@/game-fortnite/constants/statColumns.mjs";
import {
  WEAPON_CATEGORIES,
  WEAPON_CATEGORIES_SYMBOLS,
} from "@/game-fortnite/constants/weaponCategories.mjs";
import type { RankStats } from "@/game-fortnite/models/rank-statistics.mjs";
import {
  getParams,
  getWeaponsPageParamsStr,
  WEAPON_PAGE_DEFAULT_OPTIONS,
} from "@/game-fortnite/utils/get-params.mjs";
import { tagsHaveStrings } from "@/game-fortnite/utils/get-weapon-category-from-tags.mjs";
import getWeaponKey from "@/game-fortnite/utils/get-weapon-key.mjs";
import Static from "@/game-fortnite/utils/static.mjs";
import useWeaponsFromItems from "@/game-fortnite/utils/use-weapons-from-items.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import type { SORT_DIR } from "@/shared/DataTable.jsx";
import { DataTableExtSort as DataTable } from "@/shared/DataTable.jsx";
import BarChart from "@/shared-fps/BarChart.jsx";
import { displayRate } from "@/util/helpers.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ItemContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--sp-3);

  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    @container (width <= 400px) {
      max-width: calc(5 * var(--sp-5));
    }
  }
`;

const DataTableStyle = () => css`
  th:has(div.big-column),
  .big-column {
    white-space: nowrap;
    width: var(--sp-30) !important;
  }
`;

const Filters = ({
  mode,
  searchText,
  setSearch,
  sortBy,
  setSortBy,
  weaponCategory,
  setWeaponCategory,
}) => {
  const { t } = useTranslation();
  const modeSymbol = Object.getOwnPropertySymbols(FORTNITE_PLAYLISTS).find(
    (s) => FORTNITE_PLAYLISTS[s].key === mode.key,
  );
  const isRankedMode = FORTNITE_PLAYLISTS[modeSymbol]?.isRanked;
  const hiddenItems = isRankedMode ? 5 : 4;

  return (
    <FiltersContainer hiddenItems={hiddenItems} className={undefined}>
      <TextInput
        defaultValue={searchText}
        placeholder={t("fortnite:search.weapons", "Search Weapons")}
        onValueChange={setSearch}
        Icon={SearchIcon}
      />
      <Modes defaultOptions={WEAPON_PAGE_DEFAULT_OPTIONS} />
      <Queues defaultOptions={WEAPON_PAGE_DEFAULT_OPTIONS} />
      <Ranks defaultOptions={WEAPON_PAGE_DEFAULT_OPTIONS} />
      <WeaponCategories
        weaponCategory={weaponCategory}
        setWeaponCategory={setWeaponCategory}
      />
      <Seasons />
      <Sort cols={WEAPON_STAT_COLUMNS} sortBy={sortBy} setSortBy={setSortBy} />
    </FiltersContainer>
  );
};

function Charts({ sortedColumn }) {
  const { searchParams } = useRoute();
  const {
    fortnite: {
      seasons,
      stats: { ranks },
    },
  } = useSnapshot(readState);

  const { selectedMode: mode } = getParams(
    searchParams,
    WEAPON_PAGE_DEFAULT_OPTIONS,
  );
  const paramString = getWeaponsPageParamsStr(searchParams, {
    season: seasons[0]?.id,
  });
  const rankStats = ranks[paramString] as RankStats;

  const rankGraphData = useMemo(() => {
    if (!rankStats || rankStats instanceof Error) return [];
    return Object.getOwnPropertySymbols(FORTNITE_RANKS)
      .filter((i) => {
        return rankStats[FORTNITE_RANKS[i].backendKey];
      })
      .map((i) => {
        const yField =
          rankStats[FORTNITE_RANKS[i].backendKey][sortedColumn.key];
        return {
          xField: FORTNITE_RANKS[i].key,
          yField,
          players: rankStats[FORTNITE_RANKS[i].backendKey].players,
          avatar: null,
        };
      });
  }, [rankStats, sortedColumn.key]);

  const playerCount = useMemo(() => {
    return rankGraphData.reduce((acc, d) => acc + d.players, 0);
  }, [rankGraphData]);

  if (!mode.isRanked || rankGraphData.length <= 0) return null;

  return (
    <BarChart
      title={sortedColumn.rankedChartTitle}
      subtitle={[
        "fortnite:charts.players",
        "{{playerCount}} players",
        {
          playerCount: getLocaleString(playerCount),
        },
      ]}
      data={rankGraphData}
      getBarColor={(v) => {
        const rank = Object.getOwnPropertySymbols(FORTNITE_RANKS).find(
          (i) => FORTNITE_RANKS[i].key === v,
        );
        return FORTNITE_RANKS[rank].color;
      }}
      getTooltipContent={(v) => {
        return (
          <RankedChartTooltip
            rank={v.xField}
            stats={[
              {
                key: sortedColumn.key,
                display: sortedColumn.display,
                value: sortedColumn.formatter(v.yField),
              },
              {
                key: "players",
                display: ["fortnite:stats.players", "Players"],
                value: v.players,
              },
            ]}
          />
        );
      }}
      getXValue={(key) => Static.getRankIcon(key)}
      chartProps={{
        yAxisConf: {
          show: true,
          type: "linear",
          tickCount: 3,
          orientation: "right",
          tickFormat: (v) => sortedColumn.formatter(v),
        },
      }}
    />
  );
}

function StatisticsWeapons() {
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const {
    fortnite: {
      stats: { weaponsDerived: weaponsStats },
      seasons,
    },
  } = useSnapshot(readState);
  const paramString = getWeaponsPageParamsStr(searchParams, {
    season: seasons[0]?.id,
  });
  const stats = weaponsStats[paramString];

  const weapons = useWeaponsFromItems();

  const cols = useMemo(
    () => [
      {
        display: t("common:weapon", "Weapon"),
        align: "left",
        primary: true,
        key: "weapon",
      },
      ...WEAPON_STAT_COLUMNS.map((c) => ({ ...c, display: t(...c.display) })),
    ],
    [t],
  );
  const { selectedMode: mode } = getParams(
    searchParams,
    WEAPON_PAGE_DEFAULT_OPTIONS,
  );
  const [searchText, setSearch] = useState("");
  const [weaponCategory, setWeaponCategory] = useState(
    WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS.fortniteAllWeapons].key,
  );
  const [sortBy, setSortBy] = useState(WEAPON_STAT_COLUMNS[0].key);
  const [sortCol, setSortCol] = useState(1);
  const [sortDir, setSortDir] = useState<SORT_DIR>("DESC");
  const setSort = useCallback(
    (val) => {
      const sortedColIndex = cols.findIndex((c) => c.key === val);
      setSortBy(val);
      setSortCol(sortedColIndex);
    },
    [cols],
  );
  const sortedColumn = WEAPON_STAT_COLUMNS.find((w) => w.key === sortBy);

  const rows = useMemo(() => {
    if (!stats) return null;
    return Object.entries(stats)
      .map(([weaponId, weaponStats]) => {
        const weapon = weapons[weaponId.toLowerCase()];
        if (!weapon) return null;
        if (
          weaponCategory !==
            WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS.fortniteAllWeapons]
              .key &&
          weapon.category !== weaponCategory
        )
          return null;
        if (tagsHaveStrings(["pickaxe"], weapon.gameplayTags)) return null;
        return [
          {
            display: (
              <ItemContainer>
                <ItemBox
                  id={weapon.id}
                  name={weapon.displayName}
                  rarity={weapon.rarity.toUpperCase() as Rarity}
                  src={`${appURLs.CDN}/fortnite/images/${weapon.icon}.webp`}
                />
                <p className="type-callout--bold shade0 name">
                  {weapon.displayName}
                </p>
              </ItemContainer>
            ),
            value: weapon.displayName,
            link: `/fortnite/database/weapon/${getWeaponKey(
              weapon.displayName,
            )}`,
          },
          {
            display: getLocaleString(weaponStats.elims, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
            value: weaponStats.elims,
          },
          {
            display: displayRate(weaponStats.accuracy, 100),
            value: weaponStats.accuracy,
          },
          {
            display: displayRate(weaponStats.headshotPercent, 100),
            value: weaponStats.headshotPercent,
          },
          {
            display: getLocaleString(weaponStats.damage, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }),
            value: weaponStats.damage,
          },
        ];
      })
      .filter((w) => w);
  }, [stats, weapons, weaponCategory]);

  return (
    <Container>
      <Filters
        mode={mode}
        searchText={searchText}
        setSearch={setSearch}
        sortBy={sortBy}
        setSortBy={setSort}
        weaponCategory={weaponCategory}
        setWeaponCategory={setWeaponCategory}
      />
      <Charts sortedColumn={sortedColumn} />
      <DataTable
        cols={cols}
        rows={rows}
        sortDir={sortDir}
        sortCol={sortCol}
        sortColTiebreak={3}
        indexCol
        searchText={searchText}
        searchCol={0}
        className={DataTableStyle()}
        setSelectedSortCol={setSortCol}
        setSelectedSortDir={setSortDir}
      />
    </Container>
  );
}

export default StatisticsWeapons;
