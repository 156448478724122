import { styled } from "goober";

const Tooltip = styled("div")`
  *:first-child {
    margin-bottom: var(--sp-1);
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--sp-1);
    img {
      margin: auto 0;
    }
  }

  .stat-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--sp-2);
  }

  .stat-title {
    color: var(--shade0-75);
  }

  .stat-value {
    font-weight: 625;
  }

  .highlight {
    color: var(--turq);
    font-weight: 725;
  }
`;

export default Tooltip;
