import {
  WEAPON_CATEGORIES,
  WEAPON_CATEGORIES_SYMBOLS,
} from "@/game-fortnite/constants/weaponCategories.mjs";

export function tagsHaveStrings(search: string[], tags: string[]) {
  return tags.some((tag) =>
    search.some((s) => tag.toLowerCase().includes(s.toLowerCase())),
  );
}

function getCategory(tags: string[] | null | undefined): string | undefined {
  if (!tags?.length || tagsHaveStrings(["MurkyVole.Arsenal"], tags)) return;
  if (tagsHaveStrings(["melee"], tags))
    return WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS.melee].key;
  if (
    tagsHaveStrings(["_launcher", "grenade"], tags) &&
    !tagsHaveStrings(["consumable"], tags)
  )
    return WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS.explosives].key;
  if (tagsHaveStrings(["pistol"], tags))
    return WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS.pistols].key;
  if (tagsHaveStrings(["shotgun"], tags))
    return WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS.shotguns].key;
  if (tagsHaveStrings(["minigun"], tags))
    return WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS["light-machine-guns"]]
      .key;
  if (tagsHaveStrings(["dmr"], tags))
    return WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS["marksman-rifles"]].key;
  if (tagsHaveStrings(["crossbow"], tags))
    return WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS.crossbows].key;
  if (tagsHaveStrings(["bow"], tags))
    return WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS.bows].key;
  if (tagsHaveStrings(["smg"], tags))
    return WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS.smgs].key;
  if (tagsHaveStrings(["sniper"], tags))
    return WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS.snipers].key;
  if (tagsHaveStrings(["assault"], tags))
    return WEAPON_CATEGORIES[WEAPON_CATEGORIES_SYMBOLS["assault-rifles"]].key;
}

export default getCategory;
