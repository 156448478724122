import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { FORTNITE_RANKS } from "@/game-fortnite/constants/ranks.mjs";
import Static from "@/game-fortnite/utils/static.mjs";
import ContentContainer from "@/shared/ContentContainer.jsx";
import Tooltip from "@/shared-fps/BarChartTooltip.style";

export const Container = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  gap: var(--sp-4);
`;

export const StatsCards = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--sp-4);

  @container content-container (width <= 700px) {
    display: flex;
    flex-direction: column;
  }
`;

export function RankedChartTooltip({ rank: key, stats }) {
  const { t } = useTranslation();
  const rankSymbol = Object.getOwnPropertySymbols(FORTNITE_RANKS).find(
    (i) => FORTNITE_RANKS[i].key === key,
  );
  const rank = FORTNITE_RANKS[rankSymbol];
  return (
    <Tooltip>
      <div className="header">
        <img src={Static.getRankIcon(rank.key)} width={16} height={16} />
        <p className="type-caption--bold shade0">{t(...rank.t)}</p>
      </div>
      {stats.map((s) => {
        return (
          <div className="stat-line" key={s.key}>
            <p className="type-caption stat-title">{t(...s.display)}</p>
            <p className="type-caption--semi stat-value">{s.value}</p>
          </div>
        );
      })}
    </Tooltip>
  );
}

type PlayerProps = {
  title: string;
  subtitle?: string;
  icon?: string | React.ReactNode;
  iconLeft?: string | React.ReactNode;
  team?: string[];
};

const PlayerContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: var(--sp-4);
  align-items: center;

  .icon-left {
    &,
    img {
      width: var(--sp-6);
      height: var(--sp-6);
    }
    overflow: hidden;
  }
  .icon {
    &,
    img {
      height: var(--sp-8);
      width: var(--sp-8);
    }
    border-radius: 50%;
    overflow: hidden;
  }

  .title-container {
    display: flex;
    flex-direction: column;
  }
`;

export function Player({ title, icon, iconLeft, subtitle }: PlayerProps) {
  return (
    <PlayerContainer>
      {iconLeft && <div className="icon-left">{iconLeft}</div>}
      {icon && <div className="icon">{icon}</div>}
      {title && !subtitle ? (
        <p className="type-body2-form--bold shade0">{title}</p>
      ) : (
        <div className="title-container">
          <p className="type-callout--bold shade0">{title}</p>
          <p className="type-caption--semi shade2">{subtitle}</p>
        </div>
      )}
    </PlayerContainer>
  );
}
